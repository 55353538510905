import React from "react";

import Layout from "../components/layout";

const PrivacyPage = () => (
  <Layout noRobots={true}>
    <div className="document-page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="document-bold">Polityka Prywatności</h1>
            <h2 className="document-bold">Aplikacji kidsup</h2>

            <h4 className="document-bold">1. Nasze dane.</h4>
            <h5>
              Kidsup Spółka Akcyjna z siedzibą w Obornikach 64-600, ul. Kalinowa
              6 wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd
              Rejonowy - Nowe Miasto i Wilda w Poznaniu, VIII Wydział
              gospodarczy Krajowego Rejestru Sądowego pod numerem KRS
              0000699834, posiadający numer NIP 6060101216, REGON 368568706.
            </h5>

            <h4 className="document-bold">
              2. Kto jest administratorem danych i w jakim celu je przetwarza.
            </h4>
            <h5>
              Administratorem twoich danych jest placówka, do której uczęszcza
              dziecko. Zgodnie z art. 37 RODO - Rozporządzenie Parlamentu
              Europejskiego i Rady (EU) 2016/679 z dnia 27 kwietnia 2016 r. Oraz
              uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
              danych) Jesteśmy podmiotem przetwarzającym twoje dane, które
              otrzymaliśmy od administratora placówki w celu umożliwienia tobie
              korzystania z naszej aplikacji. Możesz skontaktować się z nami pod
              adresem kontakt@kidsup.pl
            </h5>
            <h4 className="document-bold">
              3. Jakie dane otrzymujemy i przetwarzamy abyś mógł korzystać z
              naszej aplikacji.
            </h4>
            <h5>
              Jeśli jesteś naszym klientem - (imię i nazwisko, dane placówki,
              NIP, numer telefonu, adres e-mail) Jeśli jesteś pracownikiem -
              (imię i nazwisko, numer telefonu, adres e-mail) Jeśli jesteś
              rodzicem - (imię i nazwisko oraz twojego dziecka, numer telefonu,
              adres e-mail)
              <br />
              <br />
              <span className="document-bold document-bigger">
                Dodatkowo
              </span>{" "}
              przetwarzamy dane osobowe wszystkich użytkowników naszej aplikacji
              (parametry techniczne urządzenia użytkownika, data i czas serwera,
              adres IP, lokalizację urządzenia, które łączy się z aplikacja)
              <br />
              <br />
              <span className="document-bold document-bigger">
                Pliki cookies
              </span>{" "}
              - informacje tekstowe, na twoim urządzeniu, które mogą być
              odczytywane przez nas system teleinformatyczny.
            </h5>
            <h4 className="document-bold">
              4. Jak długo będziemy przetwarzać oraz komu ujawniamy dane.
            </h4>
            <h5>
              Dane będziemy przetwarzać przez okres 5 lat, zgodnie z
              obowiązującymi przepisami prawa polskiego, głównie do celów
              podatkowych. Dane ujawniamy organom administracji państwowej,
              uprawnione do tego z mocy prawa.
            </h5>
            <h4 className="document-bold">5. Jakie masz prawa</h4>
            <h5>
              Poniżej masz listę praw jakie tobie przysługują. Oczywiście
              dokładamy wszelkich starań abyś był zadowolony ze współpracy z
              nami. -prawo dostępu do danych osobowych - art.15 RODO -prawdo do
              sprostowania danych - art.16 RODO -prawo do usunięcia danych -
              art.17 RODO -prawo do ograniczenia przetwarzania - art.18 RODO
              -prawo do przenoszenia danych - art.20 RODO -prawo do wniesienia
              sprzeciwu wobec przetwarzania - art.21 RODO
            </h5>
            <h4 className="document-bold">6. Skargi do organu nadzorczego</h4>
            <h5>
              Organem nadzorczym jest Prezes Urzędu Ochrony Danych Osobowych -
              możesz złożyć skargę na dres ul. Stawiki 2, 00-913 Warszawa lub
              e-mail: <a href={"mailto:kancelaria@uodo.gov.pl"}> kancelaria@uodo.gov.pl</a> więcej informacji na stronie
              <a href="https://uodo.gov.pl" > https://uodo.gov.pl</a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
